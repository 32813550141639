<template>
  <div v-if="active" >
    <v-dialog v-model="active" persistent width="300">
      <v-card
      class="text-center"
        :color="`${color} ${$vuetify.theme.dark ? 'black' : 'white'}`"
        dark
      >
        <v-card-text class="text-center--primary">
          <span class=""> Please stand by... </span>
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    active: Boolean,
    text: String,
    color: String,
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
